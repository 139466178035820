<template>
  <div class="playerStats">
    <a
      class="backArrow"
      @click="!thisIsFirstRoute ? $router.go(-1) : $router.push('/players')"
    ></a>
    <div v-if="playerObj.firstName !== ''">
      <img :src="imgSrc" />
      <h3>{{ playerObj.firstName }} {{ playerObj.lastName }}</h3>
      <div v-if="addPlayerMsg" id="addPlayerMsg">{{ addPlayerMsg }}</div>

      <div class="tabs">
        <input id="tabone" type="radio" name="tabs" checked="checked" />
        <label for="tabone">News</label>
        <div class="tab">
          <h1>Player news</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>

        <input id="tabtwo" type="radio" name="tabs" />
        <label for="tabtwo">Points</label>
        <div class="tab">
          <h1>Fantasy points</h1>
          <table id="ptsTable">
            <tr>
              <th>Week</th>
              <th>Points</th>
            </tr>
            <tr v-for="i in fantasyPts" :key="i.week">
              <td>Week {{ i.week }}</td>
              <td>{{ i.fantasyPoints }}</td>
            </tr>
          </table>

          <div v-if="loading.pts" class="loading"></div>
        </div>

        <input id="tabthree" type="radio" name="tabs" />
        <label for="tabthree">Stats</label>
        <div class="tab">
          <h1>Player stats</h1>
          <ul id="statList">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <li v-for="(s, key) in stats">
              <h3>{{ key }}</h3>
              <ul>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <li v-for="(subStat, subKey) in s">
                  <span>{{ subKey }}:</span>
                  <span>{{ subStat }}</span>
                </li>
              </ul>
            </li>
          </ul>
          <div v-if="loading.stats" class="loading"></div>
        </div>
      </div>

      <div class="actionBtns">
        <button class="add" title="Add to lineup" @click="addToLineup"></button>
        <button class="compare" title="Compare players"></button>
      </div>
    </div>
    <div v-else>
      <h3>Player not found.</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerStats',
  props: {
    player: { type: Object, default: () => {} },
    imgSrc: { type: String, default: '' } // TODO: replace with default image
  },
  data() {
    return {
      playerObj: { id: null, firstName: '', lastName: '' },
      addPlayerMsg: '',
      fantasyPts: [],
      loading: {
        player: false,
        pts: false,
        stats: false
      }
    };
  },
  computed: {
    name() {
      return this.playerObj
        ? `${this.playerObj.firstName} ${this.playerObj.lastName}`
        : '';
    },
    // inUserLineup() {
    //   if (!this.playerObj.position) return undefined;

    //   return this.$store.state.userLineup[this.playerObj.position].some(
    //     p => p.id === this.playerObj.id
    //   );
    // },
    thisIsFirstRoute() {
      return this.$store.state.firstRoute === this.$route;
    },
    stats() {
      // const stats = {};
      if (this.playerObj.stats) {
        // for (let key in this.playerObj.stats) {
        //   stats = { ...stats, ...this.playerObj.stats[key] };
        // }
        return this.playerObj.stats;
      }

      return {};
    }
  },
  async mounted() {
    try {
      if (this.player) {
        this.playerObj = this.player;
      } else {
        this.loading.player = true;
        this.playerObj =
          (await this.$store.dispatch(`getFantasyPlayer`, {
            playerId: this.$route.params.id
          })) || this.playerObj;

        this.loading.player = false;
      }

      this.loading.stats = true;
      this.playerObj.stats.then(data => {
        if (data.gamelogs.length) {
          this.playerObj.stats = data.gamelogs[0].stats;
        }
        this.loading.stats = false;
      });

      this.loading.pts = true;
      this.fantasyPts = await this.$store.dispatch(`getFantasyPts`, {
        player: `${this.playerObj.firstName}-${this.playerObj.lastName}-${this.playerObj.id}`
      });
      this.loading.pts = false;
    } catch (e) {
      this.loading.player = this.loading.pts = this.loading.stats = false;
    }
  },
  methods: {
    async addToLineup() {
      try {
        // console.log(`playerObj`, this.playerObj);
        this.addPlayerMsg = await this.$store.dispatch(
          `addToLineup`,
          this.playerObj
        );
      } catch (e) {
        // console.log(`add to lineup failed.`);
      }
    }
  }
};
</script>

<style>
.actionBtns {
  display: flex;
  justify-content: space-between;
}

.actionBtns button {
  background: none;
  border: none;
  color: #efb30d;
}

button.add::before {
  content: '\002B';
  font-size: 1.9rem;
}

button.compare::before {
  content: '\21C4';
  font-size: 1.8rem;
}

button:disabled {
  color: gray;
}

/* .playerStats > div {
  flex: 1;
} */

/* tabs */
.tabs {
  display: flex;
  flex-wrap: wrap;
}

.tabs label {
  order: 1;
  display: block;
  padding: 1rem 0;
  flex: 1;
  cursor: pointer;
  background: hsla(0, 0%, 43%, 0.45);
  font-weight: bold;
  transition: color ease 0.2s;
}

.tabs .tab {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem;
  background: hsla(0, 0%, 43%, 0.45);
  margin-top: 1rem;
}

.tabs input[type='radio'] {
  display: none;
}

.tabs input[type='radio']:checked + label {
  color: #d1603d;
}

.tabs input[type='radio']:checked + label + .tab {
  display: block;
}

#ptsTable {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #5f6260;
}
#ptsTable tr:nth-child(odd) {
  background: #5f6260;
}
th,
td {
  padding: 0.25rem;
}

/* @media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }

  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
} */

#addPlayerMsg {
  background: gray;
  padding: 1rem;
  margin-bottom: 1rem;
  /* display: inline-block;
  border-radius: 2px; */
}

#statList > li {
  flex-direction: column;
  text-align: left;
}

#statList > li h3 {
  margin: 0.5rem 0;
}

#statList > li li {
  padding: 0.25rem 0;
}
</style>
